import {
  ContactsCard,
  ContactsCardContainer,
  ContactText,
  ContactUsContainer,
  ContactUsWrapper,
} from "./ContactUsElements"
import PageHeadWrapper from "../../components/Elements/PageHeadWrapper"
import { useContext, useEffect } from "react"
import { MenuContext } from "../../context/menuContext"
import { animateScroll as scroll } from "react-scroll/modules"
import { FaInstagram, FaAmazon, FaTiktok } from "react-icons/fa"
import {
  BreadCrumbs,
  BreadCrumbsLink,
  BreadCrumbsP,
} from "../../components/Elements/StyledElements"
import SEO from "../../components/SEO"

const ContactUs = () => {
  const { togglePages } = useContext(MenuContext)

  useEffect(() => {
    togglePages()
    scroll.scrollToTop()
  }, [])
  return (
    <>
      <SEO
        description="Armreels | Contact us | The perfect mechanism for centerpin fishing"
        title="Armreels | Contact us"
        keywords="armreels centerpin centre-pins contact"
        canonical="https://armreels.com/contact"
      />
      <ContactUsContainer id="contact">
        {/* <PPButton/> */}
        <ContactUsWrapper>
          <BreadCrumbs>
            <BreadCrumbsLink to="/">HOME</BreadCrumbsLink>
            <BreadCrumbsP>›</BreadCrumbsP>
            <BreadCrumbsLink style={{ cursor: "default" }}>
              CONTACT US
            </BreadCrumbsLink>
          </BreadCrumbs>
          <PageHeadWrapper text="Contact us" />

          <div style={{ display: "grid", justifyContent: "center" }}>
            <ContactText>
              The only official manufacturer of unique centerpin reels, the
              quality of which has been known for more than 10 years. All the
              developments stand out for their special reliability and easy to
              use. A professional approach to business plus a high level of
              responsibility allow us to eventually create a product that every
              angler who owns our reels is rightfully proud of.
              <br />
              <br />
              We answer any questions by e-mail: <i>sales@armreels.com</i>
              <br />
              <br />
              <i>Armreels. Tumanyan street 31, 0002, Yerevan, Armenia</i>
              <br/>
              <br/>
              <i>+37491470040</i>
            </ContactText>
            <ContactsCardContainer>
              <ContactsCard
                className="ContactsCard1"
                href="https://www.tiktok.com/@armreels_centerpins?_t=8cmAfWEetcH"
                target="_blank"
                onClick={() => {
                  try {
                    window.gtag("event", "ToTTFromContact", {
                      event_name: "click",
                    })
                  } catch (error) {
                    console.log(error)
                  }
                }}
              >
                <FaTiktok style={{ width: "50px", height: "auto" }} />
                <h1>Watch our videos on TikTok</h1>
                <br/>
                <p>Follow our activities on TikTok</p>
              </ContactsCard>
              <ContactsCard
                className="ContactsCard2"
                href="https://www.instagram.com/armreels_centerpin/"
                target="_blank"
                onClick={() => {
                  try {
                    window.gtag("event", "ToIMFromContact", {
                      event_name: "click",
                    })
                  } catch (error) {
                    console.log(error)
                  }
                }}
              >
                <FaInstagram style={{ width: "50px", height: "auto" }} />
                <h1>Follow the news on Instagram</h1>
                <p>Follow our activities on Instagram</p>
              </ContactsCard>
              {/* <ContactsCard
                className="ContactsCard3"
                href="https://a.co/d/fgF5j2j"
                target="_blank"
                onClick={() => {
                  try {
                    window.gtag("event", "ToAMFromContact", {
                      event_name: "click",
                    })
                  } catch (error) {
                    console.log(error)
                  }
                }}
              >
                <FaAmazon style={{ width: "50px", height: "auto" }} />
                <h1>Our store on Amazon</h1>
                <p>You can also buy Armreels centerpins on Amazon</p>
              </ContactsCard> */}
            </ContactsCardContainer>
          </div>
          {/* <ContactImgWrap>
            <ContactImg src={Icon} alt="centerpin reel" />
          </ContactImgWrap> */}
        </ContactUsWrapper>
      </ContactUsContainer>
    </>
  )
}

export default ContactUs
