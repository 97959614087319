import { useState, useEffect, useContext } from "react"
import { FaBars, FaAmazon } from "react-icons/fa"
import { MenuContext } from "../../context/menuContext"
import {
  Nav,
  NavbarContainer,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  BasketIcon,
  NavLogoText,
  NavLogoImg,
  NavLogoWrap,
  BasketIconMobile,
  NavbarContainerMob,
  NavLogoWrapMob,
  NavLogoImgMob,
  NavbarLink,
  NavLogoImgLink
} from "./NavbarElements"
import logo from "../../images/logo2.png"
import logoTAAR from "../../images/logoTAAR.png"

import { ShopContext } from "../../context/shopContext"
import Basket from "./Basket"
import { Link } from "react-router-dom"

function Navbar() {
  const { toggleNavbar, isHome, togglePages, width } = useContext(MenuContext)
  const { order, allCountInCart } = useContext(ShopContext)

  const [scrollNav, setScrollNav] = useState(false)
  // const [badge, setBadge] = useState(0)

  const changeNav = () => {
    if (isHome) {
      if (window.scrollY > 80) setScrollNav(true)
      else setScrollNav(false)
    }
  }

  useEffect(() => window.addEventListener("scroll", changeNav), [])
  useEffect(() => togglePages(), [])

  if (width < 800)
    return (
      <Nav scrollNav={scrollNav} isHome={isHome}>
        <NavbarContainerMob>
          <MobileIcon onClick={toggleNavbar}>
            <FaBars />
          </MobileIcon>
          <NavbarLink
            href="https://a.co/d/fgF5j2j"
            target="_blank"
            onClick={() => {
              try {
                window.gtag("event", "ToAMFromMobNavbar", {
                  event_name: "click",
                })
              } catch (error) { 
                console.log(error)
              }
            }}
          >
            <FaAmazon style={{ color: "white", width: "28px", height: "28px", visibility: "hidden" }} />
          </NavbarLink>
          <NavLogoWrapMob to="/">
            <NavLogoImg
              src={logo}
              alt="armreels centerpin fish"
              className="ItemImage"
              scrollNav={scrollNav}
            />
            {/* <NavLogoText>ARMREELS</NavLogoText> */}
          </NavLogoWrapMob>
          <NavbarLink
            href="https://taarfishing.store/products/arm-reels-centerpin"
            target="_blank"
            onClick={() => {
              try {
                window.gtag("event", "ToTAARFromMobNavbar", {
                  event_name: "click",
                })
              } catch (error) {
                console.log(error)
              }
            }}
          >
            <NavLogoImgMob
              src={logoTAAR}
              alt="TAAR centerpin fish"
              className="ItemImage"
              scrollNav={scrollNav}
              style={{ width: "60px", height: "60px" }}
            />
          </NavbarLink>

          <BasketIconMobile to="/cart" style={{ textDecoration: "none" }}>
            <Basket allCountInCart={allCountInCart} />
          </BasketIconMobile>
        </NavbarContainerMob>
      </Nav>
    )
  return (
    <Nav scrollNav={scrollNav} isHome={isHome}>
      <NavbarContainer>
        <NavLogoWrap to="/">
          <NavLogoImg
            src={logo}
            alt="armreels centerpin fish"
            className="ItemImage"
            scrollNav={scrollNav}
          />
          <NavLogoText>ARMREELS</NavLogoText>
        </NavLogoWrap>
        {/* <MobileIcon onClick={toggleNavbar}>
          <FaBars />
        </MobileIcon> */}
        {/* <BasketIconMobile to="/cart" style={{ textDecoration: "none" }}>
          <Basket allCountInCart={allCountInCart} />
        </BasketIconMobile> */}
        <NavMenu>
          <NavItem>
            <NavLinks
              to="/centerpins"
              onClick={() => {
                try {
                  window.gtag("event", "navBarToAboutCentrepins", {
                    event_name: "click",
                  })
                } catch (error) {
                  console.log(error)
                }
              }}
            >
              ABOUT CENTERPINS
            </NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks
              to="/gallery"
              onClick={() => {
                try {
                  window.gtag("event", "navBarToGallery", {
                    event_name: "click",
                  })
                } catch (error) {
                  console.log(error)
                }
              }}
            >
              GALLERY
            </NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks
              to="/shop"
              onClick={() => {
                try {
                  window.gtag("event", "navBarToShop", {
                    event_name: "click",
                  })
                } catch (error) {
                  console.log(error)
                }
              }}
            >
              SHOP
            </NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks
              to="/contact"
              onClick={() => {
                try {
                  window.gtag("event", "navBarToContactUs", {
                    event_name: "click",
                  })
                } catch (error) {
                  console.log(error)
                }
              }}
            >
              CONTACT US
            </NavLinks>
          </NavItem>
        </NavMenu>
        <NavbarLink
          href="https://a.co/d/fgF5j2j"
          target="_blank"
          onClick={() => {
            try {
              window.gtag("event", "ToAMFromNavbar", {
                event_name: "click",
              })
            } catch (error) {
              console.log(error)
            }
          }}
        >
          <FaAmazon style={{ color: "white", width: "20px", height: "20px", visibility: "hidden" }} />
        </NavbarLink>
        <NavbarLink
          href="https://taarfishing.store/products/arm-reels-centerpin"
          target="_blank"
          onClick={() => {
            try {
              window.gtag("event", "ToTAARFromNavbar", {
                event_name: "click",
              })
            } catch (error) {
              console.log(error)
            }
          }}
        >
          <NavLogoImg
            src={logoTAAR}
            alt="TAAR centerpin fish"
            className="ItemImage"
            scrollNav={scrollNav}
          />       
        </NavbarLink>
        <BasketIcon to="/cart" style={{ textDecoration: "none" }}>
          <Basket allCountInCart={allCountInCart} />
        </BasketIcon>
      </NavbarContainer>
    </Nav>
  )
}

export default Navbar
