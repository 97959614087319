import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { useContext, useEffect, useState } from "react"
import { ShopContext } from "../../context/shopContext"
import pdfL from "../../manuals/Armreel-45-Service-Manual-L-2023.pdf"
import pdfR from "../../manuals/Armreel-45-Service-Manual-R-2023.pdf"
import {
  AboutLink,
  AMLink,
  AMLinkWrap,
  ItemAddBtn,
  ItemAddWrap,
  ItemCarousel,
  ItemCarouselImgWrap,
  ItemCarouselWrap,
  ItemCharacterisctics1,
  ItemCharacterisctics2,
  ItemComponentContainer,
  ItemComponentWrapper,
  ItemContainer,
  ItemCount,
  ItemDownloadButtons,
  ItemInfo,
  ItemInfoH1,
  ItemInfoP,
  ItemPrice,
  ItemShortDescription,
  ItemShortDescriptionWrap,
} from "./ItemElements"
import { items } from "../../items"
import { MenuContext } from "../../context/menuContext"
import { animateScroll as scroll } from "react-scroll/modules"
import NotFound from "../../pages/NotFound"
import ChangeTypeElement from "./ChangeTypeElement"
import {
  BreadCrumbs,
  BreadCrumbsLink,
  BreadCrumbsP,
  DownloadButton,
  // DownloadButtons,
} from "../Elements/StyledElements"
import { FaAmazon } from "react-icons/fa"
import { AiOutlineCloudDownload } from "react-icons/ai"
import SEO from "../SEO"

const total_price = (current_price, count) => current_price.substring(1) * count

const ItemPageComponent = () => {
  const { addToBasket, addToBadge } = useContext(ShopContext)
  const { togglePages, width } = useContext(MenuContext)
  const [count, setCount] = useState(1)
  const [value, setValue] = useState(false)
  const [carouselWidth, setCarouselWidth] = useState()
  useEffect(() => {
    togglePages()
    scroll.scrollToTop()
    setCarouselWidth(handleCarouselWidth())
  }, [])

  const handleCarouselWidth = () => {
    if (width > 1300) return 60
    else if (width <= 1300 && width > 768) return 50
    else if (width < 768 && width > 400) return 36
    else if (width <= 400) return 34
  }

  const decrement = () => {
    if (count > 1) setCount(count - 1)
  }
  const increment = () => {
    if (count < 10) setCount(count + 1)
  }

  const handleToggle = () => {
    setValue(!value)
  }

  try {
    const path = window.location.pathname.substring(6)
    const item = items.find((item) => item.name === path)

    const handleAdd = () => {
      // window.localStorage.setItem('cart', {item: item, count: count});
      addToBasket(item, count, value ? "RH" : "LH")
      addToBadge(count)

      try {
        window.gtag("event", "addToCartItem", {
          event_name: "click",
          item: `${item.name} ${value} ${count}`,
        })
      } catch (error) {
        console.log(error)
      }
    }

    let canonical = "https://armreels.com/" + path
    let description =
      path +
      " | Armreels catalog | Welcome the world of eternal classic of centerpin fishing, where the main tool is your skill, complemented by an impeccable mechanism."
    let title =
      path + " | Armreels catalog | The perfect mechanism for centerpin fishing"
    let keywords =
      path +
      " armreels centerpin centre-pins catalog bait-casting air brake fishing"

    return (
      <>
        <SEO
          description={description}
          title={title}
          keywords={keywords}
          canonical={canonical}
        />
        <ItemComponentContainer>
          <BreadCrumbs>
            <BreadCrumbsLink to="/">HOME</BreadCrumbsLink>
            <BreadCrumbsP>›</BreadCrumbsP>
            <BreadCrumbsLink to="/shop">SHOP</BreadCrumbsLink>
            <BreadCrumbsP>›</BreadCrumbsP>
            <BreadCrumbsLink style={{ cursor: "default" }}>
              {item.name.toUpperCase()}
            </BreadCrumbsLink>
          </BreadCrumbs>
          <ItemComponentWrapper>
            <ItemContainer className="ItemContainer">
              <ItemCarouselWrap className="ItemCarouselWrap">
                <ItemCarousel
                  className="ItemCarousel"
                  dynamicHeight={true}
                  // centerMode={true}
                  // autoPlay infiniteLoop transitionTime={1000}
                  thumbWidth={carouselWidth}
                >
                  <ItemCarouselImgWrap>
                    <img
                      alt="centerpin armreels bait casting air brake fishing"
                      src={item.currentSrc}
                      // src={require(`../../images/items/${path}/image1.png`)}
                    />
                  </ItemCarouselImgWrap>
                  <ItemCarouselImgWrap>
                    <img
                      alt="centerpin armreels bait casting air brake fishing"
                      src={item.currentSrc2}
                      // src={require(`../../images/items/${path}/image2.png`)}
                    />
                  </ItemCarouselImgWrap>
                  <ItemCarouselImgWrap>
                    <img
                      alt="centerpin armreels bait casting air brake fishing"
                      src={item.currentSrc3}
                      // src={require(`../../images/items/${path}/image3.png`)}
                    />
                  </ItemCarouselImgWrap>
                  <ItemCarouselImgWrap>
                    <img
                      alt="centerpin armreels bait casting air brake fishing"
                      src={item.currentSrc4}
                      // src={require(`../../images/items/${path}/image4.png`)}
                    />
                  </ItemCarouselImgWrap>
                  <ItemCarouselImgWrap>
                    <img
                      alt="armreels centerpin bait casting air brake fishing"
                      src={item.currentSrc5}
                      // src={require(`../../images/items/${path}/image5.png`)}
                    />
                  </ItemCarouselImgWrap>
                </ItemCarousel>
              </ItemCarouselWrap>
              <ItemInfo className="ItemInfo">
                <ItemInfoH1>{item.fullname}</ItemInfoH1>
                <ItemPrice>{item.current_price}</ItemPrice>
                <ItemShortDescriptionWrap className="ItemShortDescription">
                  <ItemShortDescription style={{ border: "none" }}>
                    <ItemInfoP>{item.short_description1}</ItemInfoP>
                  </ItemShortDescription>
                  <ItemCharacterisctics1>
                    <ul>
                      {item.features.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  </ItemCharacterisctics1>
                  <ItemShortDescription style={{ borderBottom: "none" }}>
                    <ItemInfoP>{item.short_description2}</ItemInfoP>
                    <div
                      style={{
                        margin: "0 auto",
                        textAlign: "center",
                        width: "fit-content",
                        lineHeight: "1.2rem",
                      }}
                    >
                      <AboutLink
                        to="/centerpins"
                        style={{
                          color: "white",
                          paddingTop: "1rem",
                          textDecoration: "underline",
                        }}
                      >
                        Read more...
                      </AboutLink>
                    </div>
                  </ItemShortDescription>
                  <ItemCharacterisctics2>
                    <ul>
                      {item.characteristics.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  </ItemCharacterisctics2>
                </ItemShortDescriptionWrap>
                {item.have_manual && (
                  <ItemDownloadButtons>
                    <DownloadButton
                      onClick={() => {
                        try {
                          window.gtag("event", "Download_AM-45-L_manual", {
                            event_name: "click",
                          })
                        } catch (error) {
                          console.log(error)
                        }
                      }}
                    >
                      <AiOutlineCloudDownload
                        style={{
                          marginTop: "auto",
                          marginBottom: "auto",
                          width: "24px",
                          height: "24px",
                        }}
                      />
                      <a href={pdfL} download>
                        Service manual L-Hand
                      </a>
                    </DownloadButton>
                    <DownloadButton
                      onClick={() => {
                        try {
                          window.gtag("event", "Download_AM-45-R_manual", {
                            event_name: "click",
                          })
                        } catch (error) {
                          console.log(error)
                        }
                      }}
                    >
                      <AiOutlineCloudDownload
                        style={{
                          marginTop: "auto",
                          marginBottom: "auto",
                          width: "24px",
                          height: "24px",
                        }}
                      />
                      <a href={pdfR} download>
                        Service manual R-Hand
                      </a>
                    </DownloadButton>
                  </ItemDownloadButtons>
                )}

                <div
                  style={{
                    display: "grid",
                    gap: "0.6rem",
                    justifyContent: "center",
                    marginBottom: "1rem",
                    fontWeight: "bold",
                  }}
                >
                  <p>No-Risk Purchase</p>
                  <p>100% Money-Back Guarantee</p>
                </div>
                <ItemAddWrap>
                  <ChangeTypeElement
                    onColor="black"
                    isOn={value}
                    handleToggle={handleToggle}
                  />
                  <div
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginBottom: "10px",
                    }}
                  >
                    <p style={{ fontWeight: "bold" }}>
                      {value ? "Reel with your right" : "Reel with your left"}
                    </p>
                  </div>
                  <ItemCount>
                    <p
                      style={{
                        cursor: "pointer",
                        margin: "auto 0",
                        fontSize: "1.2rem",
                        fontWeight: "bold",
                      }}
                      onClick={decrement}
                    >
                      -
                    </p>
                    <p
                      style={{
                        fontWeight: "bold",
                        margin: "auto 0",
                        fontSize: "1.2rem",
                      }}
                    >
                      {count}
                    </p>
                    <p
                      style={{
                        cursor: "pointer",
                        margin: "auto 0",
                        fontSize: "1.2rem",
                        fontWeight: "bold",
                      }}
                      onClick={increment}
                    >
                      +
                    </p>
                  </ItemCount>
                  <ItemAddBtn to="/cart" onClick={handleAdd}>
                    <p style={{ margin: "auto 0" }}>
                      ADD TO CART -{" "}
                      <b>
                        ${total_price(item.current_price, count)} ({count})
                      </b>
                    </p>
                  </ItemAddBtn>
                </ItemAddWrap>
                {/* {item.onAmazon && (
                  <div style={{ marginTop: "1rem" }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <p style={{ textAlign: "center", fontWeight: "bold" }}>
                        OR
                      </p>
                    </div>

                    <AMLinkWrap>
                      <AMLink
                        href="https://a.co/d/fgF5j2j"
                        target="_blank"
                        style={{ justifyContent: "center", display: "flex" }}
                        onClick={() => {
                          try {
                            window.gtag("event", "ToAMFromItem", {
                              event_name: "click",
                            })
                          } catch (error) {
                            console.log(error)
                          }
                        }}
                      >
                        <p>BUY ON AMAZON</p>
                        <FaAmazon
                          style={{
                            color: "black",
                            width: "1.4rem",
                            height: "1.4rem",
                            margin: "auto 0",
                          }}
                        />
                      </AMLink>
                    </AMLinkWrap>
                  </div>
                )} */}
              </ItemInfo>
            </ItemContainer>
            {/* <ItemDescriprionWrap>Description</ItemDescriprionWrap> */}
          </ItemComponentWrapper>
        </ItemComponentContainer>
      </>
    )
  } catch (error) {
    console.log(error)
    return <NotFound />
  }
}

export default ItemPageComponent
