export const items = [
  {
    name: "Armreel-45",
    fullname: "Armreel-45 LH/RH Centerpin Reel",
    shop_name: "ARMREEL-45",
    availability: "available",
    previous_price: "$390",
    current_price: "$490",
    vendor_code: "12345",
    isNew: false,
    isTop: true,
    onAmazon: true,
    features: [
      "Casting brakes",
      "Brake pad",
      "Tension knob",
      "Anti-reverse clicker",
      "Oil port",
      "Sand-resist assembly",
    ],

    characteristics: [
      "Mass: 7,6 oz. / 215 gramms",
      "Spool diam: 4.5 in. / 114 mm",
      "Spool width: 0.7 in. / 18 mm",
      "Capacity: 142 yds/ 130 m (line 0.35 mm)",
      "Rotation: 2 x ABEC-7 marine-grade bearings",
      "Tractive effort: 25 lbs/15 kg",
      "Warranty: The warranty against manufacturing defects is 5 years. Since 2009 of the master's work, only 0.1% of buyers have applied for warranty service.",
    ],
    short_shop_description:
      "Armreel-45 / Centerpin reel 4.5 in. with casting brakes and brake pad (ABEC-7)",
    short_description1:
      "Right—hand - winding clockwise.\nLeft—hand - winding counter-clockwise.\n\nThe Armreel-45 model is used for a variety of fishing styles, but its advantages are most fully revealed in floatfishing (Bologna, Nottingham) and spinning.\n\nThe reel parts are made of anodised aviation alloy AA2024 and high-strength steel.",
    short_description2:
      "Allows to cast baits weighing from 1/14 oz. (with proper training).\nAllows to cast super-heavy baits.\nDesigned to catch trophy fish. Reel has an exceptionally powerful thrust, the design has a huge margin of safety.\nThe reel is resistant to sand, dirt, water and requires minimal maintenance.\nDoes not require the use of a special rod.\nIt is possible to use multifilament lines with a strength of up to 40 lbs, which significantly reduces the likelihood of breakage and loss of baits. Oil port allows to lubricate bearings without disassembling the spool.",
    have_manual: true,
    currentSrc: "https://ik.imagekit.io/armreels/Items/Armreel-45/image1.png?updatedAt=1685387841455",
    currentSrc2: "https://ik.imagekit.io/armreels/Items/Armreel-45/image2.png?updatedAt=1685387841619",
    currentSrc3: "https://ik.imagekit.io/armreels/Items/Armreel-45/image3.png?updatedAt=1685387841635",
    currentSrc4: "https://ik.imagekit.io/armreels/Items/Armreel-45/image4.png?updatedAt=1685387841610",
    currentSrc5: "https://ik.imagekit.io/armreels/Items/Armreel-45/image5.png?updatedAt=1685387841673"
  },
  {
    name: "Armreel-45-E",
    fullname: "Armreel-45E LH/RH Centerpin Reel",
    shop_name: "ARMREEL-45-E",
    availability: "available",
    previous_price: "$420",
    current_price: "$490",
    vendor_code: "12344",
    isNew: false,
    isTop: false,
    onAmazon: false,
    features: [
      "Casting brakes",
      "Brake pad",
      "Tension knob",
      "Anti-reverse clicker",
      "Oil port",
      "Sand-resist assembly",
    ],
    characteristics: [
      "Mass: 7,7 oz. / 220 gramms",
      "Spool diam: 4.5 in. / 114 mm",
      "Spool width: 1.0 in. / 23 mm",
      "Capacity: 200 yds/185 m (line 0.35 mm)",
      "Rotation: 2 x ABEC-7 marine-grade bearings",
      "Tractive effort: 35 lbs/15 kg",
      "Warranty: The warranty against manufacturing defects is 5 years. Since 2009 of the master's work, only 0.1% of buyers have applied for warranty service.",
    ],
    short_shop_description:
      "Armreel-45 (E)/ Centerpin reel 4.5 in. with casting brakes and brake pad (ABEC-7)",
    short_description1:
      "Enlarged Armreel-45 with increased fishing line capacity.\n\nRight—hand - winding clockwise.\nLeft—hand - winding counter-clockwise.\n\nThe Armreel-45E model is used for a variety of fishing styles, but its advantages are most fully revealed in floatfishing (Bologna, Nottingham) and spinning.\n\nThe reel parts are made of anodised aviation alloy AA2024 and high-strength steel.",
    short_description2:
      "Allows to cast baits weighing from 1/14 oz. (with proper training).\nAllows to cast super-heavy baits.\nDesigned to catch trophy fish. Reel has an exceptionally powerful thrust, the design has a huge margin of safety.\nThe reel is resistant to sand, dirt, water and requires minimal maintenance.\nDoes not require the use of a special rod.\nIt is possible to use multifilament lines with a strength of up to 40 lbs, which significantly reduces the likelihood of breakage and loss of baits. Oil port allows to lubricate bearings without disassembling the spool.",
    have_manual: false,
    currentSrc: "https://ik.imagekit.io/armreels/Items/Armreel-45-E/image1.png?updatedAt=1685388335161",
    currentSrc2: "https://ik.imagekit.io/armreels/Items/Armreel-45-E/image2.png?updatedAt=1685388335325",
    currentSrc3: "https://ik.imagekit.io/armreels/Items/Armreel-45-E/image3.png?updatedAt=1685388335468",
    currentSrc4: "https://ik.imagekit.io/armreels/Items/Armreel-45-E/image4.png?updatedAt=1685388335509",
    currentSrc5: "https://ik.imagekit.io/armreels/Items/Armreel-45-E/image5.png?updatedAt=1685388335204"
  },
  {
    name: "Armreel-35",
    fullname: "Armreel-35 LH/RH Centerpin Reel",
    shop_name: "ARMREEL-35",
    availability: "available",
    previous_price: "$290",
    current_price: "$390",
    vendor_code: "12346",
    isNew: false,
    isTop: false,
    onAmazon: false,
    features: [
      "Thick edge",
      "Anti-reverse clicker",
      "Sand-resist",
      "Shock-resistant",
    ],
    characteristics: [
      "Mass: 3,5 oz. / 98 gramms",
      "Spool diam: 3.5 in. /  80 mm",
      "Spool width: 0.55 in. / 14 mm",
      "Capacity : 131 yds / 120 m (line 0.35 mm)",
      "Rotation: 2 x ABEC-5 marine-grade bearings",
      "Tractive effort: 35 lbs / 15 kg",
      "Warranty: The warranty against manufacturing defects is 5 years. Since 2009 of the master's work, only 0.1% of buyers have applied for warranty service.",
    ],
    short_shop_description: "Armreel-35 / 3.5 in./ float reel / ice reel",
    short_description1:
      "Armreel-35 is great for icefishing, spinners and for float fishing with tackle release, and other fishing methods that require a light, durable and reliable reel with a clicker, a rigid retainer and easy rotation of the spool.\n\nThe reel parts are made aviation alloy AA2024 and high-strength steel. The spool and the body of the reel are machine-turned and anodised.\n\nRight—hand - winding clockwise.\nLeft—hand - winding counter-clockwise.",
    short_description2:
      "The compact, reliable, lightweight and durable reel is perfect for completing the emergency kit for tourists, travelers and people whose activities may be associated with survival in the wild. The reel is resistant to shocks, sand and snow, easy to disassemble for cleaning and lubrication during travel.",
    have_manual: false,
    currentSrc: "https://ik.imagekit.io/armreels/Items/Armreel-35/image1.png?updatedAt=1685388361785",
    currentSrc2: "https://ik.imagekit.io/armreels/Items/Armreel-35/image2.png?updatedAt=1685388361670",
    currentSrc3: "https://ik.imagekit.io/armreels/Items/Armreel-35/image3.png?updatedAt=1685388361788",
    currentSrc4: "https://ik.imagekit.io/armreels/Items/Armreel-35/image4.png?updatedAt=1685388361745",
    currentSrc5: "https://ik.imagekit.io/armreels/Items/Armreel-35/image5.png?updatedAt=1685388361729"

  },
  {
    name: "Armreel-25",
    fullname: "Armreel-25 LH/RH Centerpin Reel",
    shop_name: "ARMREEL-25",
    availability: "available",
    previous_price: "$250",
    current_price: "$290",
    vendor_code: "12347",
    isNew: true,
    onAmazon: false,
    isTop: false,
    features: ["Anti-reverse clicker", "Sand-resist"],
    characteristics: [
      "Mass: 2,6 oz. / 75 gramms",
      "Spool diam: 2.5 in. /  64 mm",
      "Spool width: 0.51 in. / 13 mm",
      "Capacity : 65 yds / 60 m (line 0.35 mm)",
      "Rotation: 2 x ABEC-5 marine-grade bearings",
      "Tractive effort: 25 lbs / 10 kg",
      "Warranty: The warranty against manufacturing defects is 5 years. Since 2009 of the master's work, only 0.1% of buyers have applied for warranty service.",
    ],
    short_shop_description: "Armreel-25 / 2.5 in. float reel/ ice reel",
    short_description1:
      "The Armreel-25 series model is currently the smallest in the family of our reels and the lightest, while it retains all the best properties — easy running, strength, reliability and durability. The spool has a rigid stopper of the original design with a soft clicker.\n\nRight—hand - winding clockwise.\nLeft—hand - winding counter-clockwise.",
    short_description2:
      "Armreel-25 is designed for fishing methods that require a compact, lightweight but powerful reel with an easily rotating spool. The reel is resistant to sand, dirt and water ingress.",
    have_manual: false,
    currentSrc: "https://ik.imagekit.io/armreels/Items/Armreel-25/image1.png?updatedAt=1685388385545",
    currentSrc2: "https://ik.imagekit.io/armreels/Items/Armreel-25/image2.png?updatedAt=1685388385388",
    currentSrc3: "https://ik.imagekit.io/armreels/Items/Armreel-25/image3.png?updatedAt=1685388385481",
    currentSrc4: "https://ik.imagekit.io/armreels/Items/Armreel-25/image4.png?updatedAt=1685388385346",
    currentSrc5: "https://ik.imagekit.io/armreels/Items/Armreel-25/image5.png?updatedAt=1685388385675"
  },
]
